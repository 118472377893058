$white: #fff;
$yellow: #fcd22c;
$green: #68ac09;

$section-top:    50px;
$section-bottom:    60px;
.text-center{
  text-align: center;
}
.top-info-line{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background: $yellow;
  text-align: center;
  color: #000;
}
body{
  font-family: 'Titillium Web', sans-serif;
}
*{
  box-sizing: border-box;
}
.heading-wrapper{
  text-align: center;
}
h2{
  font-size: 45px;
  font-weight: 300;
  text-transform: uppercase;
  @include bp('xs'){
    font-size: 20px;
  }
  @include bp('sm'){
    font-size: 30px;
  }
  &.heading-yellow{
    color: $yellow;
  }
  &.heading-white{
    color: $white;
  }
  span{
    font-weight: 600;
  }
}
p{
  font-size: 20px;
  line-height:26px;
  color: #2d2b2e;
  @include bp('xs'){
    font-size: 14px;
    line-height: 18px;
  }
  @include bp('sm'){
    font-size: 18px;
    line-height: 24px;
  }
  &.paragraph-white{
    color: $white;
  }
}
.button{
  width: 255px;
  padding-top:13px;
  padding-bottom: 15px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #000;
  text-transform: uppercase;
  transition: all .2s;
  @include bp('sm'){
    width: 200px;
  }
  &:hover{
    text-decoration: none;
  }
  &-yellow{
    color: $yellow;
    border: 1px solid $yellow;
    &:hover, &:active, &:focus{
      color: $green;
      text-decoration: none;
      border: 1px solid $green;
    }
  }
  &-white{
    color: $white;
    border: 1px solid $white;
    &:hover, &:active, &:focus{
      color: $yellow;
      text-decoration: none;
      border: 1px solid $yellow;
    }
  }
}
header{
  widows:   100%;
  height: 500px;
  background-image: url('../images/header-bg.jpg');
  background-position: top right;
  background-color: #0b0805;
  background-repeat: no-repeat;
  position: relative;
  @include bp('xs'){
    background-size:cover;
  }
  .burger-menu{
    position:absolute;
    top: 16px;
    right: 20px;
    display:none;
    width: 40px;
    height: 3px;
    background: $white;
    transition: all .2s;
    z-index: 100;
    margin-top: 20px;
    @include bp('xs'){
      display: none;
    }
    &:before{
      content: "";
      position: absolute;
      top: -14px;
      width: 40px;
      height: 3px;
      display:inline-block;
      right: 0px;
      border-radius: 10px;
      background: $white;
    }
    &:after{
      content: "";
      position: absolute;
      top: 14px;
      width: 40px;
      height: 3px;
      display:inline-block;
      right: 0px;
      border-radius: 10px;
      background: $white;
    }
    &.active{
      transform: rotate(45deg);
      &:after{
        opacity: 0;
      }
      &:before{
        transform: rotate(-95deg);
        top: 0;
      }
    }
  }
  .menu-line{
    width: 100%;
    height: 80px;
    background: rgba(#54890e, .7);
  }
  .logo{
    position: absolute;
    top:10px;
    @include bp('xs'){


    a{
      display:inline-block;
      width:100%;
      text-align: center;
    }
    }
    img{
      max-width: 100%;
      @include bp('xs'){
        width: 100px;
      }
    }
  }
  .facebook-btn{
    background: #2b4772;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 13px;
    padding-top: 20px;
    position: absolute;
    top:-10px;
    right: 20px;
    color: $white;
    border-radius: 6px;
    text-transform: uppercase;
    @include bp('xs'){
      display: none;
    }
    &:hover, &:active, &:focus{
      color: $yellow;
      text-decoration: none;
    }
  }
  .menu{
    text-align: right;
    float:right;
    margin-right: 10px;
    @include bp('xs'){
      display: none;
    }
    li{
      text-align: right;
      float:left;
      padding-left: 25px;
      font-weight: 600;
      padding-top: 50px;
      &:first-child{
        padding-left:0;
      }
      a{
        font-size: 13px;
        color: $white;
        text-transform: uppercase;
        padding-bottom: 8px;
        transition: all .2s;
        border-bottom: 2px solid transparent;
        &:hover, &:active, &:focus{
          color: $yellow;
          border-bottom: 2px solid $yellow;
          text-decoration: none;
        }
      }
    }
  }
}
#zdrave_obedy{
  padding-top: 130px;
  @include bp('xs'){
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 120px;
    margin-left: -15px;
    margin-right: -15px;
    background: rgba(#000, .8);
  }
  p{
    padding-top: 5px;
  }
  .button-yellow{
    margin-top: 50px;
  }
}
#dnes-varime{
  background-image: url('../images/dnes-varime-bg.jpg');
  background-position: center -20px;
  background-repeat: no-repeat;
  background-color:#2d2b2e;
  padding-top: $section-top;
  padding-bottom: $section-bottom;
  p{
    text-align: center;
    padding-top:50px;;
  }
  h3{
    text-align: center;
    font-size:30px;
    padding-top: 40px;
    color: $yellow;
    text-transform: uppercase;
  }
  .daily-carousel-group-wrapper{
    position:relative;
  }
  .daily-menu-item{
    width: 580px;
    border: 1px solid $yellow;
    border-radius: 6px;
    margin-top:50px;
    margin-bottom:40px;
    margin-left:auto;
    margin-right:auto;
    color: $yellow;
    padding-top:15px;
    padding-bottom:10px;
    padding-left:15px;
    @include bp('xs'){
      width: 100%;
      text-align: center;
    }
    .day{
      font-weight: 300;
      font-size: 60px;
      line-height: 1;
      @include bp('xs'){
        font-size: 30px;
      }
    }
    .date{
      padding-left: 40px;
      padding-right: 32px;
      display: inline-block;
      @include bp('xs'){
        padding-left: 10px;
      }
    }
    .line{
      width:1px;
      background: $yellow;
      height: 50px;
      display: inline-block;
      @include bp('xs'){
        display:none;
      }
    }
    .price{
      font-size: 48px;
      color: $green;

      font-weight: 200;
      padding-left:25px;
      line-height: 1;
      @include bp('xs'){
        width: 100%;
        display:inline-block;
        font-size: 26px;
        text-align: center;
        padding-left:0;
      }
    }
  }
  .daily-menu-group{
    text-align:center;
    .daily-menu-group-item{
      padding-top: 25px;
      &-less-top{
        padding-top: 10px;
      }
      &:first-child{
        padding-top: 0;
      }
    }
    .title, .item{
      display: inline-block;
      width: 100%;
    }
    .title{
      color:$green;
      font-size:13px;
      strong{
        font-size:17px;
        font-weight: 600;
      }
    }
    .item{
      color: $white;
      font-size: 20px;
      line-height:26px;
    }
    p{
      padding-top:0;
      color: #717171;
      font-size: 13px;
    }
    .button{
      margin-top: 40px;
      display:inline-block;
    }


  }
  .more-menu{
    display:none;
    }
  .display-none{
      display:none !important;
    }
  .paradajka{
    position:absolute;

  }
}
#chutne_zdravo{
  background-image: url('../images/chutne_zdravo-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;
  background-color:#2e2a30;
  padding-top: $section-top;
  padding-bottom: 20px;
  background-attachment: fixed;
  @include bp('xs'){
    background-size: cover;
    background-attachment: scroll
    }
  p{
    padding-top:50px;
    padding-left: 30px;
    padding-right:30px;
    text-align: center;
  }
  .inside-section{
    width:100%;
    padding-top: 30px;
    padding-bottom: 40px;
    margin-top:35px;
    background: rgba(#000, .3);
    .button{
      max-width:100%;
    }
  }
  .chutne-section{
    text-align: center;
    p{
      padding:0;
      padding-top: 20px;
      font-weight: 600;
    }
    @include bp('xs'){
      margin-top: 20px;
    }
    @include bp('sm'){
      margin-top: 20px;
    }
  }
  .chutne-section-deli{
    text-align: center;
    padding-top: 70px;
    @include bp('xs'){
      display:none;
    }
    @include bp('sm'){
      display:none;
    }
  }
  .button{
    margin-top:60px;
  }
}

.small-line-box{
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  background-image: url('../images/newsletter_bg.png');
  &-facebook{
    background-image:none;
    background-color: #144487;
    @include bp('xs'){
      text-align: center;
    }
  }
  .success-message{
    font-weight: 700;
    font-size: 19px;
    text-transform: uppercase;
    display:none;
  }
  p{
    font-size: 15px;
    line-height: 19px;

    padding-top: 5px;
  }
  input{
    width: 100%;
    min-height:38px;
    border: 1px solid #2d2b2e;
    background:transparent;

    background-position: 240px center;
    background-repeat: no-repeat;
    padding: 15px;
    padding-right: 25px;
    display: inline-block;
    border-radius: 6px;
    text-transform: uppercase;
    &.redBorder{
      border: 1px solid red;
    }
    &:focus, &:active{
      outline: 0;
      border: 1px solid $green;
    }
  }
  .newsletter-submit{
    background:transparent;
    border:0;
    position:absolute;
    right:25px;top:18px;
    height:18px;
    width:11px;
    background-repeat: no-repeat;
    background-image: url('../images/input-arrow.png');
    display:inline-block;
    &:active, &:focus, &:hover{
      outline: 0;
    }
  }
  @include placeholder(){
    color: #2d2b2e;
    font-size:14px;
    text-transform: uppercase;
  }
}
#ako_unas{
  background-image: url('../images/ako_to_vyzera.jpg');
  background-position: center center;
  background-repeat: no-repeat;

  background-color:#2d2b2e;
  padding-top: $section-top;
  padding-bottom: $section-bottom;
  .gallery-item{
    margin-top: 70px;
    border-radius:50%;
    overflow:hidden;
    border: 4px solid $green;
    img{
      width: 100%;

    }
    &:hover{
      img{
        opacity: .8;
      }
    }
  }
}
#map_canvas{
  width:100%;
  height:435px;
}
.created-by{
  background:#272727;
  text-align: center;
  p{
    font-size:12px;
    color:#909090;
    a{
      color:#909090;
      font-weight: 600;
    }
  }
}
.carousel-prev, .carousel-next{
  display:inline-block;
  height: 18px;
  color: transparent;
  width:11px;
  background:url('../images/carousel-controls.png');
  background-repeat:no-repeat;
  position:absolute;
  top:85px;
  left:40px;
  z-index: 9999999999;
  @include bp('xs'){
    left: 10px;
  }
  @include bp('sm'){
    left:0;
  }
  &:hover, &:active, &:focus{
    background-position: 0 -20px;
  }
}
.carousel-next{
  right: 40px;
  left:inherit;
  background-position: -11px 0px;
  @include bp('xs'){
    right: 10px;
  }
  @include bp('sm'){
    right:0;
  }
  &:hover, &:active, &:focus{
    background-position: -11px -20px;
  }
}
