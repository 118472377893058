@mixin bp($point, $high:'', $low:'') {

  /* XS */
  $mobile-max: 767;
  $mobile-min: 1;
  @if $point == xs {
    @if $high != '' {
      $mobile-max: $high;
    }
    @if $low != '' {
      $mobile-min: $low;
    }
  }

  /* SM */
  $portrait-min: 768;
  $portrait-max: 1129;

  @if $point == sm {
    @if $high != '' {
      $portrait-max: $high;
    }
    @if $low != '' {
      $portrait-min: $low;
    }
  }

  /* MD */
  // $tablet-min: 992;
  // $tablet-max: 1199;

  // @if $point == md {
  //   @if $high != '' {
  //     $tablet-max: $high;
  //   }
  //   @if $low != '' {
  //     $tablet-min: $low;
  //   }
  // }

  /* LG */
  $desktop-min: 1130;

  @if $point == lg {
    @if $low != '' {
      $desktop-min: $low;
    }
  }

  $mobile: "(min-width: #{$mobile-min}px) and (max-width: #{$mobile-max}px)";

  $portrait: "(min-width: #{$portrait-min}px) and (max-width: #{$portrait-max}px)";



  $desktop: "(min-width: #{$desktop-min}px)";

  @if $point == xs {
    @media #{$mobile} { @content; }
  }

  @else if $point == sm {
    @media #{$portrait} { @content; }
  }
  @else if $point == lg {
    @media #{$desktop}  { @content; }
  }

}

@mixin placeholder(){
  ::-webkit-input-placeholder {
   @content
  }

  :-moz-placeholder { /* Firefox 18- */
     @content
  }

  ::-moz-placeholder {  /* Firefox 19+ */
     @content
  }

  :-ms-input-placeholder {
    @content
  }
}

@mixin rem($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value*10}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}
* {
  box-sizing:border-box;
}
body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
.cf:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
}

  .cf-mobile:after {
    @include bp('xs'){
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }

  }
  * html .cf-mobile             { zoom: 1; } /* IE6 */
  *:first-child+html .cf-mobile { zoom: 1; } /* IE7 */
